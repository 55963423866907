export const FEATURES =[
    {
        image:'assets/images/features/feature.png',
        title:'Purchase and Order',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Inventory Management',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Vendors Notification',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Billable Expenses',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Store Management',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Easy Operation',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Multi Account Operation',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
    {
        image:'assets/images/features/feature.png',
        title:'Sales Reports',
        content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui officia deserunt mollit anim id est laborum.<br/> Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.'
    },
]