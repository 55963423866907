import React, { useEffect } from 'react';
import NavBar from '../Components/Navbar/Navbar';
import topLeft from '../assets/images/homepage/topLeft.png';
import bannerimg from '../assets/images/homepage/bannerimg.png';
import secBanner from '../assets/images/homepage/sec-banner.png';
import laptop from '../assets/images/homepage/laptop.png';
import client from '../assets/images/homepage/client.png';
import { HFEATURES, BUSINESSLEFT, BUSINESSRIGHT, GETTINGSTARTED } from '../assets/content/Homepage';
import styles from './Homepage.module.css';
import HFeature from '../Components/HFeature';

import BusinessComponent from '../Components/BusinessComponent';
import GettingStarted from '../Components/GettingStarted';
import { gsap, Power3, Power2 } from 'gsap/all';
import { Link } from 'react-router-dom';
const Homepage = (props) => {
	useEffect(() => {
		gsap.from('.title', {
			y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.2
		});
		gsap.from('.title+div', {
			y: 30, opacity: 0, ease: Power3.easeOut, delay: 0.15
		});
		gsap.from('.butn', {
			y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.2
		});
		gsap.from('.bannerimg', {
			y: -300, opacity: 0, ease: "back.out(1.7)", duration: 1
		})
	})
	return (
		<div className="homepage" style={{ width: '100%' }}>
			<section
				style={{
					backgroundImage: `url(/${topLeft})`,
					backgroundRepeat: 'no-repeat',
					backgroundColor: '#303952',


				}}
			>
				<NavBar />
				<div
					className="contain d-flex"
					style={{
						height: 'calc(100vh)',
						position: 'relative',
						zIndex: '1'
					}}
				>
					<div
						className="d-flex align-items-center banner"
						style={{ height: 'calc(100vh - 90px)' }}
					>
						<div>
							<img src={bannerimg} alt="" style={{ width: '35vw' }} className="bannerimg" />
						</div>
						<div
							className="d-flex flex-column justify-content-start justify-content-md-start ml-2  align-items-start"
						// style={{ height: 'calc(65vh - 65px)' }}
						>
							<div>
								<img src={bannerimg} alt="" style={{ width: '90vw' }} className='d-block d-md-none' />
							</div>
							<div
								className="title text-white"
								style={{
									fontSize: '3.5rem',
									fontWeight: '700',
									wordSpacing: '5px',
									lineHeight: '3.5rem'
								}}
							>
								KIMS <span style={{ color: '#FCCA1C' }}> Software</span> for every business
							</div>
							<div className="text-white my-5">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
								incididunt ut labore et dolore magna aliqua.
							</div>
							<a href="http://kims.kazi270.com/registration"  target="_blank" style={{textDecoration:'none',color:'#fff'}}>
								<div className="butn btn-yellow " >
									Start Free Trial
								</div>
							</a>
						</div>
					</div>
				</div>

				<div
					style={{
						position: 'relative',
						height: '304px',

					}}
					className='d-md-block d-none'
				>
					<img
						src={secBanner}
						alt=""
						className='sec-banner'
						style={{
							position: 'absolute',
							bottom: '0',
							width: '100%'
						}}
					/>
					<div className="text text-white d-md-block d-none" style={{ position: 'absolute', width: '40%', right: '10%', bottom: '22%' }}>
						<h3>Alchi POS for <br /> <strong>Accuracy</strong> and <strong>Efficiency</strong></h3>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
							trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui
							officia deserunt mollit anim id est laborum.
							<br /> Sed ut perspiciatis unde omnis iste natus error
							sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
						</p>
					</div>
				</div>
			</section>
			<section className={styles.section} className="d-md-none d-block my-5">
				<div className={styles.sectionTitle}>Alchi POS for  <strong>Accuracy</strong> and <strong>Efficiency</strong></div>
				<p style={{ width: '80%', margin: '0 auto', textAlign: 'justify' }}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
					trud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat, sunt in culpa qui
					officia deserunt mollit anim id est laborum.
					Sed ut perspiciatis unde omnis iste natus error
					sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
				</p>
			</section>
			<section className={styles.section}>
				<div className={styles.sectionTitle}>Major features loved by our client</div>
				<div className="features-list d-flex align-items-end contain my-3">
					{HFEATURES.map((feature) => <HFeature content={feature} />)}
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.sectionTitle}>Perfect POS software for business to grow</div>
				<div className={styles.subTitle}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</div>
				<div className="contain d-flex flex-md-row flex-column justify-content-between align-items-center mt-5">
					<div style={{ flex: '30%' }}>
						<div className="d-flex flex-column justify-content-between">
							{BUSINESSLEFT.map((item) => <BusinessComponent content={item} />)}
						</div>
					</div>
					<div style={{ flex: '40%' }}>
						<img src={laptop} alt="" style={{ width: '90%', margin: '0 auto' }} className="d-none d-md-flex" />
					</div>
					<div style={{ flex: '30%' }}>
						<div className="d-flex flex-column justify-content-between">
							{
								BUSINESSRIGHT.map((item) => <BusinessComponent content={item} />)
							}
						</div>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.sectionTitle}>Getting started is easy</div>
				<div className={styles.subTitle}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</div>
				<div className="contain d-flex flex-column flex-md-row justify-content-center align-items-center my-4" style={{}} >
					{
						GETTINGSTARTED.map(item => <GettingStarted content={item} />)
					}



				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.sectionTitle}>Pricing</div>
				<div className={styles.subTitle}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</div>
				<div className="d-flex justify-content-center mt-3 bm-5 text-center">
					<div className="pricing-box mx-3">
						<div className="package-header font-weight-bold" style={{ fontSize: '1.8rem' }}>
							<div className="light-text"> YEARLY STANDARD </div>
							<div className="price">Rs. 7500</div>
						</div>
						<div className="package-details">
							<h3 className="title text-light font-weight-bold">Lorem ipsum</h3>
							<p>Lorem ipsum dolor sit</p>
							<p>Lorem ipsum dolor sit meow</p>
							<p>Lorem ipsum dolor kaw kaw</p>
							<p>Lorem ipsum dolor kaw kaw</p>
						</div>
						<div className="package-footer">
							<div className="butn btn-grad text-white" style={{ width: '60%', margin: '0 auto' }}>
								Start Free Trial
							</div>
							<p className="text-info mt-4">Get your 30 days free trial</p>
						</div>
					</div>
					<div className="pricing-box mx-3 text-white" style={{ border: 'none', background: '#BB93FC' }}>
						<div className="package-header font-weight-bold " style={{ fontSize: '1.8rem' }}>
							<div className="text-white"> YEARLY STANDARD </div>
							<div className="price">Rs. 7500</div>
						</div>
						<div className="package-details">
							<h3 className="title text-white font-weight-bold">Lorem ipsum</h3>
							<p>Lorem ipsum dolor sit</p>
							<p>Lorem ipsum dolor sit meow</p>
							<p>Lorem ipsum dolor kaw kaw</p>
							<p>Lorem ipsum dolor kaw kaw</p>
						</div>
						<div className="package-footer">
							<div className="butn btn-white text-danger" style={{ width: '60%', margin: '0 auto' }}>
								Start Free Trial
							</div>
							<p className="text-white mt-4">Get your 30 days free trial</p>
						</div>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<div className={styles.sectionTitle}>Clients</div>
				<div className={styles.subTitle}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</div>
				<div className="d-flex contain justify-content-between flex-wrap mt-4" style={{}}>
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} className="mb-3" />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
					<img src={client} alt="" style={{ flex: '20%', height: '60px' }} />
				</div>
			</section>
		</div>
	);
};
export default Homepage;
