import React,{useEffect,useRef} from "react";
import {gsap,Power3} from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BusinessComponent = ({ content }) => {
  const businessRef = useRef();
  useEffect(()=>{
		// gsap.from(featureRef.current,{
		// 	y: 30, opacity: 0, ease: Power3.easeOut, delay: 3
		// });
		gsap.from(businessRef.current, {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger:  businessRef.current,
				start:'top 90%',
			}
		})
	})
  return (
    <div className="d-flex align-items-start mb-4 row-reverse" ref={businessRef}>
      <img
        src={content.image}
        alt=""
        className="mx-auto"
        style={{ flex: "20%",  }}
      />
      <div className="pink-text" style={{ fontSize: "1.5rem", flex: "80%",margin:'0 20px' }}>
        {content.title}
        <p className="light-text" style={{ fontSize: "1.1rem" }}>
          {content.content}
        </p>
      </div>
    </div>
  );
};
export default BusinessComponent;
 