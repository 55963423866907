import React from 'react';
import { FaLinkedinIn, FaFacebookF, FaInstagram } from 'react-icons/fa';
import footer from '../../assets/images/footer-alt.png';
import styles from './Footer.module.css';
import { Link, Redirect } from 'react-router-dom';
const Footer = (props) => {
	return (
		<div className="footer">
			<section className="d-flex flex-column justify-content-end align-items-center"
				style={{
					backgroundImage: `url(${footer})`,
					height:'100%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					
				}}
			>
				<h3 style={{ color: '#BB93FC', fontWeight: '700',marginTop:'50px',marginBottom:'20px' }}>Start Your Free Trial</h3>
				<div className="subTitle text-light" style={{width:'60%'}}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</div>

				<form className="my-4 text-center" style={{ margin: '0px auto',width:'100%' }}>
					<input type="text" placeholder="Your Email id"  />
					<input type="submit" value="Sign Up" />
				</form>
				<div className="contain d-flex justify-content-around my-3 text-light">
					<h6 className='me-auto'>Copyright &#169; Kazi Studios.</h6>
					<h6>For more information call +5260651</h6>
				</div>
			</section>
			{/* <div className=" px-4 pb-5 pt-1" style={{ borderTop: '1px solid #58595B', zIndex: '100' }}>
				<div className="container d-flex justify-content-between align-items-end">
					<div className={styles.footerNav}>
						<Link to="/features">Features</Link>
						<Link to="/about">About</Link>
						<Link to="/pricing">Pricing</Link>
						<Link to="/clients">Clients</Link>
						<Link to="/contact">Contact</Link>
					</div>
					<div className="d-flex flex-column align-items-end">
						<div>
							<FaLinkedinIn style={{ marginRight: '8px' }} />
							<FaFacebookF style={{ marginRight: '8px' }} />
							<FaInstagram />
						</div>
						<div>Alchi POS Software</div>
					</div>
				</div>
			</div> */}
		</div>
	);
};
export default Footer;
