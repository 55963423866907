import React,{useEffect,useRef} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const Feature=({content})=>{
    const featureRef = useRef();
    useEffect(()=>{
        gsap.from(featureRef.current, {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger: featureRef.current,
                toggleActions:"restart pause continue pause"
				// start:'top 90%',
			}
		})
    })
    return(
        <div className="d-flex flex-md-row flex-column feature justify-content-between text-justify my-5 feature" ref={featureRef}>
            <img src={content.image} alt=""  style={{flex:'50%',width:'80%'}} />
            <div className='text-danger font-weight-bold title' style={{flex:'50%'}}>{content.title}
            <p className='text-info font-weight-normal mt-2' dangerouslySetInnerHTML={{ __html: content.content }}></p>
            </div>
        </div>
    )
}
export default Feature;