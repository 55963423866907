import React,{useEffect,useRef} from "react";
import {gsap,Power3} from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const GettingStarted = ({ content }) => {
  const gettingStarted = useRef();
  useEffect(()=>{
		// gsap.from(featureRef.current,{
		// 	y: 30, opacity: 0, ease: Power3.easeOut, delay: 3
		// });
		gsap.from(gettingStarted.current, {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger:  gettingStarted.current,
				start:'top 90%',
			}
		})
	})
  return (
    <div style={{margin:'15px 0'}} ref={gettingStarted}>
      <img
        src={content.image}
        alt=""
        style={{ width: "90%", margin: "0 auto" }}
        className="d-flex"
      />
      <div className="purple-blue-text text-center mt-2">
        {content.title}
        <p style={{ width: "85%", margin: "0 auto", fontWeight: "400" }}>
          {content.content}
        </p>
      </div>
    </div>
  );
};
export default GettingStarted;
