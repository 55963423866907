import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import styles from "./Navbar.module.css";
import { FaBars } from "react-icons/fa";
const NavBarLarge = ({ color }) => {
  let txt = color === "dark" ? "#574B90" : "#574B90";
  return (
    // <Navbar collapseOnSelect expand="lg" className={styles.navbar} style={{zIndex:'1000'}} fixed="top" >
    //   <Container style={{width:'90%'}}>
    //     <Navbar.Brand href="/"><img src={logo} alt="logo" /></Navbar.Brand>
    //      <Navbar.Toggle style={{border:'1px solid white'}}  >
    //       <FaBars color="white"/>
    //     </Navbar.Toggle> */}
    //      <Navbar.Collapse id="responsive-navbar-nav"> 
    //       <Nav className="me-auto">
            
    //       </Nav>
    //       <Nav>
	// 	  <NavLink to="/features" className="mx-5" style={{color:`${txt}`}}>Features</NavLink>
    // 				<NavLink to="/pricing" className="mx-5" style={{color:`${txt}`}}>Pricing</NavLink>
    // 				<NavLink to="/about" className="mx-5" style={{color:`${txt}`}}>About</NavLink>
    // 				<NavLink to="/contact" className="mx-5" style={{color:`${txt}`}}>Contact</NavLink>
    // 				<NavLink to="/login" className="mx-5" style={{color:`${txt}`}}>Login</NavLink>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <Navbar collapseOnSelect expand="md" top className={styles.navbar} style={{zIndex:'1000'}}  >
    	<div className="d-flex  justify-content-between align-items-center" style={{width:'90vw',margin:'auto'}}  >
    		<br/>
    		<Navbar.Brand><Link to="/"><img src={logo} alt="logo" style={{width:'50%',height:'100%'}} /></Link></Navbar.Brand>
    		{/* <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ms-auto" /> */}
    		<Navbar.Collapse id="responsive-navbar-nav">
    			<Nav className="ml-auto nav">
    				<NavLink to="/features" className="mx-3 mx-lg-4" style={{color:`${txt}`}}>Features</NavLink>
    				<NavLink to="/pricing" className="mx-3 mx-lg-4" style={{color:`${txt}`}}>Pricing</NavLink>
    				<NavLink to="/about" className="mx-3 mx-lg-4" style={{color:`${txt}`}}>About</NavLink>
    				<NavLink to="/contact" className="mx-3 mx-lg-4" style={{color:`${txt}`}}>Contact</NavLink>
            <a href="http://kims.kazi270.com/login" className="mx-3 mx-lg-4" style={{color:`${txt}`}} target="_blank">Login</a>
    				{/* <Link to={{ pathname: "https://blog.logrocket.com/how-to-use-the-gsap-scrolltrigger-plugin-in-react/" }} target={'_blank'}  className="mx-4 mx-lg-5" style={{color:`${txt}`}}>Login</Link> */}
            {/* <Link to={{ pathname: "https://example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies" }} target="_blank" >Hello</Link> */}
    			</Nav>
    			<Nav />
    		</Navbar.Collapse>
            </div>  
    </Navbar>
  );
};
export default NavBarLarge;
