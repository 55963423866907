import React from "react";
import NavBar from "../Components/Navbar/Navbar";
import topLeft from "../assets/images/homepage/topLeft.png";
import banner from "../assets/images/contact/banner.png";

const Pricing = (props) => {
  return (
    <div className="about">
      <section
        style={{
          // backgroundImage  : `url(${topLeft})`,
          backgroundRepeat: "no-repeat",
          
        }}
      >
        <NavBar  />
        <div
          className=""
          style={{
            height: "calc(100vh - 85px)",

            marginTop: "85px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            position: "relative",
          }}
        >
          <div className="d-flex">
            <div
              className="d-flex flex-column justify-content-start mt-4"
              style={{ flex: "50%" }}
            >
              <h3
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Pricing
              </h3>
              <br />
              <div className="d-flex justify-content-center mt-3 bm-5 text-center">
                <div className="pricing-box mx-3">
                  <div
                    className="package-header font-weight-bold"
                    style={{ fontSize: "1.8rem" }}
                  >
                    <div className="light-text"> YEARLY STANDARD </div>
                    <div className="price">Rs. 7500</div>
                  </div>
                  <div className="package-details">
                    <h3 className="title text-light font-weight-bold">
                      Lorem ipsum
                    </h3>
                    <p>Lorem ipsum dolor sit</p>
                    <p>Lorem ipsum dolor sit meow</p>
                    <p>Lorem ipsum dolor kaw kaw</p>
                    <p>Lorem ipsum dolor kaw kaw</p>
                  </div>
                  <div className="package-footer">
                    <div
                      className="butn btn-grad text-white"
                      style={{ width: "60%", margin: "0 auto" }}
                    >
                      Start Free Trial
                    </div>
                    <p className="text-info mt-4">
                      Get your 30 days free trial
                    </p>
                  </div>
                </div>
                <div
                  className="pricing-box mx-3 text-white"
                  style={{ border: "none", background: "#BB93FC" }}
                >
                  <div
                    className="package-header font-weight-bold "
                    style={{ fontSize: "1.8rem" }}
                  >
                    <div className="text-white"> YEARLY STANDARD </div>
                    <div className="price">Rs. 7500</div>
                  </div>
                  <div className="package-details">
                    <h3 className="title text-white font-weight-bold">
                      Lorem ipsum
                    </h3>
                    <p>Lorem ipsum dolor sit</p>
                    <p>Lorem ipsum dolor sit meow</p>
                    <p>Lorem ipsum dolor kaw kaw</p>
                    <p>Lorem ipsum dolor kaw kaw</p>
                  </div>
                  <div className="package-footer">
                    <div
                      className="butn btn-white text-danger"
                      style={{ width: "60%", margin: "0 auto" }}
                    >
                      Start Free Trial
                    </div>
                    <p className="text-white mt-4">
                      Get your 30 days free trial
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Pricing;
