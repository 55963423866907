import React from 'react'
import NavBarLarge from './Navbar-large'
import NavbarMini from './Navbar-mini'

const Navbar = () => {
  return (
    <>
      <NavBarLarge />
      <NavbarMini/>
    </>
  )
}

export default Navbar
