import React,{useEffect,useRef} from 'react';
import NavBar from '../Components/Navbar/Navbar';
import topLeft from '../assets/images/features/topLeft.png';
import banner from '../assets/images/features/banner.png';
import play from '../assets/images/features/play.png';
import Feature from '../Components/Feature';
import {FEATURES} from '../assets/content/Feature';
import {gsap,Power3} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const Features = (props) => {
	const featureRef = useRef();
	useEffect(()=>{
		gsap.from('.title', {
			y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.2
		});
		gsap.from('.contain', {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger: featureRef.current,
				// start:'top 50%',
			}
		})
	},[])
	
	return (
		<div className="features">
			<section
				style={{
					// backgroundImage  : `url(${topLeft})`,
					backgroundRepeat : 'no-repeat',
					height           : '100vh',
					
				}}
			>
				<NavBar  />
				<div
					className="d-flex justify-content-around align-items-center"
					style={{
						width:'90%',
						margin:'0 auto',
						marginTop:'80px',
						height          : 'calc(100vh - 85px)',
						backgroundImage :
							'linear-gradient(to bottom,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),' + `url(${banner})`
					}}
				>
					<div
						className="text-center"
						style={{
							width     : '80%',
							textAlign : 'center'
						}}
					>
						<div
							className="title text-white"
							style={{
								fontSize    : '4rem',
								fontWeight  : '700',
								wordSpacing : '5px',
								lineHeight  : '4.5rem'
							}}
						>
							<span style={{ color: '#FCCA1C', fontSize: '3.2rem' }}> KIMS POS Software</span>
							<br /> ONE FOR ALL<br />
							<img src={play} alt="" />
							<a href="http://kims.kazi270.com/registration"  target="_blank" style={{textDecoration:'none',color:'#fff'}}>
								<h3 style={{ color: '#BB93FC', fontWeight: '700',margin:'10px 0' }}>Start Free Trial</h3>
								</a>
							<div className="subTitle text-white">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
								incididunt trud exercitation ullamco laboris nisi ut
							</div>
						</div>
						<form className="my-4" style={{margin:'0 auto',width:'100%'}}>
							<input type="text" placeholder="Your Email id" />
							<input type="submit" value="Sign Up" />
						</form>
					</div>
				</div>
			</section>
			<section>
				<div className="contain my-5">
					{FEATURES.map(item=><Feature content={item} ref={featureRef}/>)}

				</div>
			</section>
		</div>
	);
};
export default Features;
