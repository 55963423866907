import React, { useEffect, useRef } from 'react';
import NavBar from '../Components/Navbar/Navbar';
import topLeft from '../assets/images/features/topLeft.png';
import banner from '../assets/images/about/banner.png';
import laptop from '../assets/images/about/laptop.png';
import choose1 from '../assets/images/about/choose1.png';
import choose2 from '../assets/images/about/choose2.png';
import { gsap, Power3 } from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const About = (props) => {
	
	useEffect(() => {
		gsap.from('h3', {
			y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.2
		});
		gsap.from('h3+p',{
			y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.4
		});
		gsap.from('.butn', {
			y: 30, opacity: 0, ease: Power3.easeOut, delay: 0.5
		});
		gsap.from('.contain-1', {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger: '.sectionAbout',
				// start:'top 90%',
			}
		})
		gsap.from('.contain-2', {
			duration: 1,
			delay: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger: '.sectionAbout',
				// start:'top 90%'
			}
		})
	}, [])
	return (
		<div className="about">
			<section
				style={{
					// backgroundImage  : `url(${topLeft})`,
					backgroundRepeat: 'no-repeat',
					height: '100vh'
				}}
			>
				<NavBar />
				<div
					className=""
					style={{

						height: 'calc(100vh)',
						backgroundImage: `url(${banner})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						marginTop:'80px',
						backgroundPosition: 'bottom',
						width: '100%',
					}}
				>
					<div
						className="d-flex flex-column justify-content-center text-light align-items-start "
						style={{
							height: 'calc(100vh - 60px)', width: '80%',
							margin: '0 auto',
						}}
					>
						<h3 style={{ fontSize: '3.5rem', fontWeight: '700' }} animate={{ opacity: 0 }}  >
							KIMS <span style={{ color: '#FCB326' }}> POS Software </span>
							<br /> built for businesss growth
						</h3>
						<p style={{ width: '40%' }}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
							labore et dolore magna aliqua.
						</p>
						<div className="butn btn-white">Start Free Trial</div>
					</div>
				</div>
			</section>
			<section className="contain contain-1 about-sec my-5">
				<div className="sectionAbout">About</div>
				<p style={{}} className="my-2">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi utorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					eiusmod tempor incididunt trud exercitation ullamco laboris nisi ut Lorem ipsum dolor sit amet,
					consectetur adipiscing elit, sed do eiusmod tempor incididunt trud exercitation ullamco laboris nisi
					utLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt trud
					exercitation ullamco laboris nisi ut
				</p>
			</section>
			<section className="contain contain-2 d-flex flex-md-row flex-column justify-content-between align-items-center my-5">
				<div style={{ flex: 1 }}>
					<img src={laptop} style={{ width: '100%', margin: '0 auto' }} />
				</div>
				<div className="ms-3" style={{ flex: 1 }}>
					<div className="sectionAbout"  id="choose">Why Choose Us?</div>

					<div className="d-flex my-md-4 my-5 justify-content-center align-items-md-start align-items-center ">
						<img src={choose1} style={{ flex: '20%', width: '100%' }} width='20%' />
						<div className="ml-4" style={{ flex: '80%' }}>
							<div className="chooseTitle">Best Performance</div>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt trud.
							</p>
						</div>
					</div>
					<div className="d-flex my-md-4 my-5 justify-content-center align-items-md-start align-items-center ">
						<img src={choose2} alt="" style={{ flex: '20%' }} />
						<div className="ml-4" style={{ flex: '80%' }}>
							<div className="chooseTitle">Bar Code Compatible</div>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt trud.
							</p>
						</div>
					</div>
					<div className="d-flex my-md-4 my-5 jsutify-content-center align-items-md-start align-items-center">
						<img src={choose1} alt="" style={{ flex: '20%' }} />
						<div className="ml-4" style={{ flex: '80%' }}>
							<div className="chooseTitle">Secure Multiple Usable Accounts</div>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod incididunt trud.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
export default About;
