import React,{useEffect,useRef} from 'react';
import {gsap,Power3} from 'gsap/all';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const HFeature = ({content}) => {
	const featureRef = useRef();
	useEffect(()=>{
		// gsap.from(featureRef.current,{
		// 	y: 30, opacity: 0, ease: Power3.easeOut, delay: 3
		// });
		gsap.from(featureRef.current, {
			duration: 1,
			y: '100',
			opacity: 0,
			ease: 'ease-in',
			scrollTrigger: {
				trigger:  featureRef.current,
				start:'top 90%',
			}
		})
	})
	
	
	return (
		<div className="d-flex flex-row flex-md-column justify-content-between align-items-center my-5 hfeature	" ref={featureRef}>
			<img src={content.image} alt="" style={{ width: '30%', margin: '0 10px' }} />
			<div className='d-flex flex-column  '>
			<div className="pink-text text-center my-2 hftitle" style={{ fontSize: '1.5rem' }}  >{content.title}</div>
			<div className="text-center light-text" style={{ fontSize: '1.1rem' }}>{content.content}</div>
			</div>
		</div>
	);
};
export default HFeature;
