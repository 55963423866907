import './App.css';
import Layout from './Components/Layout';
import {useEffect} from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from 'react-router';
import Homepage from './Pages/Homepage';
import Features from './Pages/Features';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Pricing from './Pages/Pricing';
import { Login } from './Pages/Login';


function App() {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route exact path="/" element={<Homepage/>}></Route>
          <Route path="/features" element={<Features/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/contact" element={<Contact/>}></Route>
          <Route path="/pricing" element={<Pricing/>}></Route>
          {/* <Route path="/login" element={<Login/>}></Route> */}





        </Routes>
      </Layout>
    </div>
  );
}

export default App;
