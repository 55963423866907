import React from "react";
import NavBar from "../Components/Navbar/Navbar";
import topLeft from "../assets/images/homepage/topLeft.png";
import banner from "../assets/images/contact/banner.png";

const Contact = (props) => {
  return (
    <div className="contact">
      <section
        style={{
          // backgroundImage  : `url(${topLeft})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <NavBar />
        <div
          className="contact-background"
          style={{
            // backgroundImage: `url(${banner})`,
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // position: "relative",
            marginTop:'80px',
            height: "calc(100vh - 80px)",
          }}
        >
          <div className="d-flex contact-child">
            <div style={{ flex: "35%" }} className="d-none d-md-block" />
            <div
              className="d-flex flex-column justify-content-md-between align-items-center align-items-md-start mt-4"
              style={{ flex: "50%" }}
            >
              <div className="my-2 text-left" style={{ color: "black" }}>
                <div className="d-flex align-items-start justify-content-between flex-column flex-md-row">
                  <div className="mr-5 mb-5">
					  <h5>Address</h5>
                    Kazi Studios Pvt. Ltd. <br />
                    Chakupat, Lalitpur, Nepal
                  </div>
                  <div className="mr-5 text-left"><h5>Contact</h5>+977-55643210<br/>info@kazistudios.com</div>
                  {/* <div className="mr-5">kazistudios@gmail.com</div> */}
                </div>
              </div>
              <h3 style={{ fontSize: "2.5rem", fontWeight: "700" }}>
                Contact Us
              </h3>
              <br />
              <form action="">
                <div className="mb-3 d-flex flex-md-row flex-column ">
                  <input
                    type="text"
                    className="form-control contact mr-3 mb-3 mb-md-0"
                    placeholder="Full Name"
                  />
                  <input
                    type="text"
                    className="form-control contact"
                    placeholder="Address"
                  />
                </div>
                <div className="mb-3 d-flex flex-md-row flex-column">
                  <input
                    type="text"
					
                    className="form-control contact mr-3 mb-3 mb-md-0"
                    placeholder="Email id"
                  />
                  <input
                    type="text"
                    className="form-control contact"
                    placeholder="Contact Number"
                  />
                </div>
                <div className="mb-3">
                  <textarea className="form-control" rows="6" />
                </div>
                <button
                  type="submit"
                  className="butn btn-white text-info border"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
