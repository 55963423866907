import React from 'react';
import Footer from './Footer/Footer';
import NavBar from './Navbar/Navbar';
const Layout=(props)=>{
    return(
        <>
      
        {props.children}
        <Footer/>
        </>
    )
}
export default Layout;