import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import logo from "../../assets/images/logo.svg";
import * as AiIcons from 'react-icons/ai';
import * as FaIcons from 'react-icons/fa';

const NavbarMini = () => {
    const [
		sidebar,
		setSidebar
	] = useState(false);
    return (
        <div className="d-md-none d-block" style={{background:"#e7e7e7",position:'fixed',top:0,width:'100%',zIndex:100}}>
					<div className="d-flex justify-content-between align-items-center px-3">
						<div className="logo-small">
							<NavLink to="/"><img src={logo} alt="" style={{width:'35%'}} /></NavLink>
						</div>
						<div className="navbar">
							<NavLink to="#" className="menu-bars" onClick={() => setSidebar(true)}>
								<FaIcons.FaBars />
							</NavLink>
						</div>
					</div>
					<nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
						<ul className="nav-menu-items">
							<li className="navbar-toggle nav-text">
								<div to="#" className="menu-bars" onClick={() => setSidebar(false)}>
									<AiIcons.AiOutlineClose />
								</div>
							</li>
							<li className="nav-text">
								<NavLink to="/features" exact>
									Features
								</NavLink>
							</li>
							
							<li className="nav-text">
								<NavLink to="/pricing">Pricing</NavLink>
							</li>
							<li className="nav-text">
								<NavLink to="/about">About</NavLink>
							</li>
							<li className="nav-text">
								<NavLink to="/contact">Contact</NavLink>
							</li>
							<li className="nav-text">
							<a href="http://kims.kazi270.com/registration" target="_blank">Sign Up</a>

							</li>
						</ul>
					</nav>
				</div>
    )
}

export default NavbarMini;
