export const HFEATURES = [
	{
		image   : 'assets/images/homepage/easyacc.png',
		title   : 'Easy Accessibility',
		content : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},

	{
		image   : 'assets/images/homepage/accounting.png',

		title   : 'Accounting',
		content : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		image   : 'assets/images/homepage/inventory.png',
		title   : 'Inventory Management',
		content : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	},
	{
		image   : 'assets/images/homepage/multiple.png',
		title   : 'Multiple User',
		content : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
	}
];
export const BUSINESSLEFT = [
	{
		image   : 'assets/images/homepage/retail.png',
		title   : 'Retail Stores',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},
	{
		image   : 'assets/images/homepage/restaurants.png',
		title   : 'Restaurants and Hotels',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},
	{
		image   : 'assets/images/homepage/medicals.png',
		title   : 'Medicals & Pharmacies',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	}
];
export const BUSINESSRIGHT = [
	{
		image   : 'assets/images/homepage/whole.png',
		title   : 'Whole Sellers',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},
	{
		image   : 'assets/images/homepage/pet.png',
		title   : 'Pet Stores',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},
	{
		image   : 'assets/images/homepage/jewelry.png',
		title   : 'Jewelry Stores',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	}
];
export const GETTINGSTARTED = [
	{
		image   : 'assets/images/homepage/started1.png',
		title   : 'Upload',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},

	{
		image   : 'assets/images/homepage/started2.png',
		title   : 'Customise',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	},
	{
		image   : 'assets/images/homepage/started3.png',
		title   : 'Connect Hardware',
		content : 'Lorem ipsum dolor sit amet, consectetur adipising'
	}
];
